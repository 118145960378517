//  Variations to Settings (clubgrubbery)
//	----------------------
//
//  Table of Contents:
//
//	0. Palette variables
//	1. Color variables
//	2. Foundation palette variables
//		2.1 Menu active class
//		2.2 Menu arrow colors
//		2.3 Menu background color
//		2.4 Menue hover
//		2.5 Meun text color
//	3. Variations to Settings
//		3.1 Buttons
//		3.2 Menu background color
//		3.3 Menu text color
//
//	---- end table of contents ---

//	0. Palette variables
// -----------------------------------------------------------------------0
$colours: (
	colour1: (
		name: brown,
		color: #533250,
		background-color: #fff,
		lighterColour: scale-color(#ba8b56, $lightness: +25%),
		veryLightColour: scale-color(#ba8b56, $lightness: 80%),
		contrastColour: color-pick-contrast(#ba8b56),
	),
	colour2: (
		name: lightgreen,
		color: scale-color(#339933, $lightness: +72%),
		background-color: #fff,
		lighterColour: scale-color(scale-color(#339933, $lightness: +72%), $lightness: +25%),
		veryLightColour: scale-color(scale-color(#339933, $lightness: +72%), $lightness: 80%),
		contrastColour: color-pick-contrast(scale-color(#339933, $lightness: +72%)),
	),
	colour3: (
		name: palegreen,
		color: #006666,
		background-color: #fff,
		lighterColour: scale-color(#006666, $lightness: +25%),
		veryLightColour: scale-color(#006666, $lightness: 80%),
		contrastColour: color-pick-contrast(#006666),
	),
	colour4: (
		name: blue,
		color: #006666,
		background-color: #fff,
		lighterColour: scale-color(#006666, $lightness: +25%),
		veryLightColour: scale-color(#006666, $lightness: 80%),
		contrastColour: color-pick-contrast(#006666),
	),
	red: (
		name: red,
		color: #f00,
		background-color: #fff,
		lighterColour: scale-color(#f00, $lightness: +25%),
		veryLightColour: scale-color(#f00, $lightness: 80%),
		contrastColour: color-pick-contrast(#f00),
		textOnly: true,
	),
	white: (
		name: white,
		color: #fff,
		background-color: #000,
		lighterColour: scale-color(#fff, $lightness: +25%),
		veryLightColour: scale-color(#fff, $lightness: 80%),
		contrastColour: color-pick-contrast(#fff),
		textOnly: true,
	)
);
/*
$containers: (
	container1: (
		name: brown,
		color: #000000,
		background-color: #c5955e,
	),
	container2: (
		name: grey,
		color: #000000,
		background-color: #f6f6f6,
	),
	container3: (
		name: black,
		color: #ffffff,
		background-color: #161616,
	)
);
*/
@function getColour($scheme, $colour) {
	@return map-get(map-get($colours, $scheme), $colour);
}
// example use for above function; getColour(colour4, name);
// this 'gets' the colour4 name value, which equals blue

//	1. Color variables
// -----------------------------------------------------------------------1

$green: #007000; // used to default colour accordions

$hover-color-primary: #007000;
$link-color-primary: $hover-color-primary;
$link-color-hover: scale-color($hover-color-primary, $lightness: +12%);
$link-color-active: #000;
$top-menu-hover-bg: #ecfbef;
$hover-color-secondary: scale-color(getColour(colour4, color), $lightness: +95%);
$hover-color-bigbutton: scale-color(getColour(colour1, color), $lightness: +55);
$hover-color-bigbutton-darker: scale-color(getColour(colour4, color), $lightness: +55);
$secondary-text-color: getColour(colour1, color);
$primary-text-color: getColour(colour4, color);


//	2. Foundation palette variables
// -----------------------------------------------------------------------2
$foundation-palette: map-merge($foundation-palette, (

//	2.1 Menu active class
  menu-active-bg: #555, // top bar and second-menu bg active
  menu-active-color: #fff, // all menus (active class on li)
  menu-title-bar-active: #eee,
  menu-top-bar-dropdown-active-bg: #fff, // top-bar dropdown active bg
  menu-dropdown-bg: #000, // top-bar dropdown active bg (bg behind radius on top-menu when .active)

//	2.2 Menu arrow colors
  menu-arrow-color: #fff, // all menus arrow color
  menu-arrow-hover-color: #fff, // all menus arrow hover color
  menu-drilldown-arrow-color: #fff,
  menu-drilldown-arrow-hover-color: #fff,

//	2.3 Menu background color
  menu-bg: #161616, // top bar bg
  menu-bg-small: #161616, // title-bar 1st level menu bg
  menu-non-active-bg: #161616, // top and titlebar (second level) non active bg
  menu-current-bg: #f00,
  title-bg: #161616, // title-bar bg

//	2.4 Menu hover
  menu-hover-bg: #555, // top-bar and second-menu parent level menu hover
  menu-hover-color: #fff, // top-bar single level menu hover
  menu-color-hover-small: #fff,	// title-bar hover color
  menu-bg-color-hover-small: #555, // top and title bar hover bg

//	2.5 Menu text color
  menu-color: #fff, // top-bar color and title-ber icon color
  menu-border: #f00, // top-bar border color (plus topmenu bannerline color)
  anchor-color: #f00, // second-menu color
  menu-color-small: #fff, // title-bar menu color
  title-color: #fff,
  title-color-hover: #555,
));
// end Foundation palette variables

//	3. Variations to Settings
// -----------------------------------------------------------------------3

//	3.1 Buttons
$button-background-hover: scale-color($button-background, $lightness: +32%);
$button-background-hover-lightness: 32%;
$button-radius: 5px;
//$button-radius: 35px;

//	3.2 Menu background color
$topbar-background: get-color(menu-bg); // top bar bg
$menu-item-background-active: get-color(menu-active-bg); // top bar and second-menu bg active
//$dropdownmenu-submenu-background: scale-color(getColour(colour4, color), $lightness: +95%); // second-menu bg
$dropdownmenu-submenu-background: #ecfbe7; // second-menu bg
$dropdown-menu-item-background-active: get-color(menu-active-bg); // top bar and second-menu bg active
//$topbar-dropdown-background: #cacaca;

//	3.3 Menu text color
$menu-item-color-active: get-color(menu-active-color); // all menus (active class on li)
//$anchor-color: get-color(anchor-color); // second-menu color
$drilldown-arrow-color: get-color(menu-drilldown-arrow-color); // all menus arrow color
//$drilldown-arrow-hover-color: get-color(menu-drilldown-arrow-hover-color);
$dropdownmenu-arrow-color: get-color(menu-arrow-color); // all menus arrow color
$dropdown-menu-item-color-active: get-color(menu-active-color); // all menus arrow color
//$anchor-color-hover: scale-color($anchor-color, $lightness: +32%);
