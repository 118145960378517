.grid-container {
	background-image: url("/assets/img/stamp.svg");
	background-position: calc(100% - 20px) 20px;
	background-size: 25% auto;
	background-repeat: no-repeat;
}
#header {
	color: #533250;
	p {
		margin-bottom: 5px;
	}
	.headtop {
		h1 {
			font-weight: bold;
			margin-top: 1rem;
		}
		h1 span {
			color: transparent;
			text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.6), 0 0 0 #141516;
			@media only screen and (max-width: 40em) {
				font-size: 1.9rem;
			}
		}
		&:after {
			content: "";
			display: block;
			width: 370px;
			xpadding-top: 5px;
			border-bottom: 3px solid #000000;
			@media only screen and (max-width: 40em) {
				width: 273px;
			}
		}
	}
	.address {
		margin: 12px 0 10px 0;
		color: #000080;
		line-height: 1.3;
		img {
			float: right;
			clear: both;
		}
	}
	.fax {
		padding: 0 0 0 20px;
		@media only screen and (max-width: 40em) {
			display: none;
		}
	}
}
#content {
	padding-top: 5px;
	color: #000000;
	&:after {
		content: "";
		display: block;
		margin: 0 auto;
		width: 50%;
		padding-top: 20px;
		border-bottom: 3px solid #000000;
		margin-bottom: 20px;
	}
}
#footer {
	color: #533250;
	padding: 20px 0 0 0;
	.address {
		line-height: 1.3;
		img {
			float: left;
			clear: both;
			margin: 0 10px 0 0;
		}
	}
	#host {
		@media only screen and (min-width: 40.063em) {
			margin-top: 55px;
			text-align: right;
		}
	}
}


